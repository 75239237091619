import { configureStore } from "@reduxjs/toolkit";
import configReducer from "./Slice/configSlice";

const store = configureStore({
  reducer: {
    configuration: configReducer,
  },
});

export default store;
