import React from "react";
import "./../CSS/Connected.css";
import axios from "axios";
import { useDispatch } from "react-redux";
import { setIsConnected } from "../Redux/Slice/configSlice";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

export default function Connected() {
  const dispatch = useDispatch();

  const [tab, settab] = React.useState("1");
  const [data, setdata] = React.useState({});
  const [loader, setloader] = React.useState(false);
  const [loaderstop, setloaderstop] = React.useState(false);

  React.useEffect(() => {
    get_data();
  }, []);

  const get_data = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const instance = queryParams.get("instance");

    if (!instance) {
      dispatch(setIsConnected(false));
    }

    setloader(true);
    // if (!loaderstop) {
    // }

    axios
      .get(
        "https://apps.trackship.co/v1/wix/store/data?instance=" +
          instance
      )
      .then(function (response) {
        if (response.data.success) {
          dispatch(setIsConnected(true));
          setdata(response.data.data);
        } else {
          setTimeout(get_data, 10000);
          // setloaderstop(true);
          setdata({});
          dispatch(setIsConnected(false));
        }
        setloader(false);
        console.log("success", response.data.data);
      })
      .catch(function (error) {
        setTimeout(get_data, 10000);
        dispatch(setIsConnected(false));
        setloader(false);
        // setloaderstop(true);
        console.log("error", error);
      });
  };

  if (loader) {
    return (
      <Skeleton
        style={{ marginLeft: "10%", marginTop: "50px" }}
        height={"400px"}
        width={"80%"}
      />
    );
  }

  if (Object.keys(data).length > 0) {
    return (
      <div>
        <div className="header-container">
          <div className="header-title">{"TrackShip  >  Dashboard"}</div>
          <img
            className="header-logo"
            src={require("./../assets/TrackShip.png")}
            alt="logo"
          />
        </div>
        <div className="title-container">
          <div className="title-text">Dashboard</div>
        </div>
        <div className="detials-container">
          <div className="grid-container">
            <div style={{ width: "25%" }} className="grid-item">
              <img
                style={{ height: "25px" }}
                src={require("./../assets/Balance.png")}
                alt="balance icon"
              />
              <div
                style={{
                  fontSize: 20,
                  color: "#3c4758",
                  fontWeight: "600",
                  marginTop: 10,
                }}
              >
                {data.tracker_balance || 0}
              </div>
              <div style={{ fontSize: 15, color: "#3c4758", marginTop: 3 }}>
                Available Balance
              </div>
            </div>
            <div style={{ width: "25%" }} className="grid-item">
              <img
                style={{ height: "25px" }}
                src={require("./../assets/Plan.png")}
                alt="Plan icon"
              />
              <div
                style={{
                  fontSize: 20,
                  color: "#3c4758",
                  fontWeight: "600",
                  marginTop: 10,
                }}
              >
                {data.user_plan || "Free 50"}
              </div>
              <a
                onClick={() => {
                  window.open(
                    "https://my.trackship.com/settings/#billing?upgrade=true",
                    "_blank"
                  );
                }}
                style={{
                  fontSize: 15,
                  color: "#124fd6",
                  marginTop: 3,
                  textDecorationLine: "none",
                  cursor: "pointer",
                }}
              >
                {"Upgrade to Pro >"}
              </a>
            </div>
            <div style={{ width: "50%" }} className="grid-item-ovr">
              <div className="tracking-overview-title">Tracking Overview</div>
              <div className="tracking-overview-tab">
                <div
                  onClick={() => settab("1")}
                  style={{
                    borderBottom: tab === "1" ? "4px solid #3858e9" : "",
                    fontWeight: tab === "1" ? "600" : "",
                  }}
                  className="tab-title"
                >
                  Today
                </div>
                <div
                  onClick={() => settab("2")}
                  style={{
                    borderBottom: tab === "2" ? "4px solid #3858e9" : "",
                    fontWeight: tab === "2" ? "600" : "",
                  }}
                  className="tab-title"
                >
                  This month
                </div>
                <div
                  onClick={() => settab("3")}
                  style={{
                    borderBottom: tab === "3" ? "4px solid #3858e9" : "",
                    fontWeight: tab === "3" ? "600" : "",
                  }}
                  className="tab-title"
                >
                  Last 30 Days
                </div>
              </div>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <div
                  style={{ borderRight: "1px solid #e0e0e0" }}
                  className="tracking-overview-box"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <div style={{ fontSize: 14, color: "#3c4758" }}>
                        Total Shipments
                      </div>
                      <div
                        style={{
                          fontSize: 20,
                          color: "#333333",
                          fontWeight: "bold",
                          marginTop: 5,
                        }}
                      >
                        {tab === "1"
                          ? data.today.total_shipment
                          : tab === "2"
                          ? data.this_month.total_shipment
                          : tab === "3"
                          ? data.last_30_days.total_shipment
                          : 0}
                      </div>
                    </div>
                    <img
                      src={require("./../assets/Total-Shipments.png")}
                      style={{ height: 33 }}
                      alt="Total Shipments"
                    />
                  </div>
                </div>
                <div className="tracking-overview-box">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <div style={{ fontSize: 14, color: "#3c4758" }}>
                        Active Shipments
                      </div>
                      <div
                        style={{
                          fontSize: 20,
                          color: "#333333",
                          fontWeight: "bold",
                          marginTop: 5,
                        }}
                      >
                        {tab === "1"
                          ? data.today.active_shipment
                          : tab === "2"
                          ? data.this_month.active_shipment
                          : tab === "3"
                          ? data.last_30_days.active_shipment
                          : 0}
                      </div>
                    </div>
                    <img
                      src={require("./../assets/Active-Shipments.png")}
                      style={{ height: 33 }}
                      alt="Total Shipments"
                    />
                  </div>
                </div>
                <div
                  style={{
                    borderRight: "1px solid #e0e0e0",
                    borderTop: "1px solid #e0e0e0",
                  }}
                  className="tracking-overview-box"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <div style={{ fontSize: 14, color: "#3c4758" }}>
                        Delivered
                      </div>
                      <div
                        style={{
                          fontSize: 20,
                          color: "#333333",
                          fontWeight: "bold",
                          marginTop: 5,
                        }}
                      >
                        {tab === "1"
                          ? data.today.delivered
                          : tab === "2"
                          ? data.this_month.delivered
                          : tab === "3"
                          ? data.last_30_days.delivered
                          : 0}
                      </div>
                    </div>
                    <img
                      src={require("./../assets/Delivered.png")}
                      style={{ height: 33 }}
                      alt="Total Shipments"
                    />
                  </div>
                </div>
                <div
                  style={{
                    borderTop: "1px solid #e0e0e0",
                  }}
                  className="tracking-overview-box"
                >
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <div style={{ fontSize: 14, color: "#3c4758" }}>
                        Tracking Issues
                      </div>
                      <div
                        style={{
                          fontSize: 20,
                          color: "#333333",
                          fontWeight: "bold",
                          marginTop: 5,
                        }}
                      >
                        {tab === "1"
                          ? data.today.tracking_issues
                          : tab === "2"
                          ? data.this_month.tracking_issues
                          : tab === "3"
                          ? data.last_30_days.tracking_issues
                          : 0}
                      </div>
                    </div>
                    <img
                      src={require("./../assets/Tracking-Issues.png")}
                      style={{ height: 33 }}
                      alt="Total Shipments"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
