import { useSelector } from "react-redux";
import "./App.css";
import Connected from "./Page/Connected";
import Disconnected from "./Page/Disconnected";

function App() {
  const isConnected = useSelector((state) => state.configuration.isConnected);

  // console.log(isConnected);

  return (
    <div className="App">{isConnected ? <Connected /> : <Disconnected />}</div>
  );
}

export default App;
