import { createSlice } from "@reduxjs/toolkit";

const configSlice = createSlice({
  name: "configuration",
  initialState: {
    isConnected: true,
  },
  reducers: {
    setIsConnected(state, action) {
      state.isConnected = action.payload;
    },
  },
});

export const { setIsConnected } = configSlice.actions;
export default configSlice.reducer;
