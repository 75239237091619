import React, { useState } from "react";
import "./../CSS/Disconnected.css";

export default function Disconnected() {
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayClick = () => {
    setIsPlaying(true);
  };

  return (
    <div className="main-container">
      <div className="main-title">Your Post-Shipping & Delivery Autopilot</div>
      <div className="description">
        Trackship is a Multi-Carrier Shipment Tracking API that seamlessly
        integrates into your Wix store and auto- tracks your shipments,
        automates your orders workflow, reduces the time spent on customer
        service and lets you provide a superior post-purchase experience to your
        customers.
      </div>
      <div
        onClick={() => {
          // window.top.location.href = "https://apps.trackship.co/v1/wix/store/connect" + window.location.search;
          window.open(
            "https://apps.trackship.co/v1/wix/store/connect" +
              window.location.search,
            "_blank"
          );
        }}
        className="connect-btn"
      >
        Connect your store
      </div>
      {!isPlaying ? (
        <div
          style={{
            display: "inline-block",
            position: "relative",
            border: "1px solid #e0e0e0",
            borderRadius: "5px",
            padding: "10px",
            width: 700,
            marginTop: 50,
          }}
        >
          <img
            src={require("./../assets/youtube-thumbnail.png")}
            alt="Shipment Tracking Interface"
            style={{ width: "100%", borderRadius: "10px" }}
          />
          <div
            onClick={handlePlayClick}
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              border: "none",
              padding: "10px",
              cursor: "pointer",
            }}
          >
            <img
              src={require("./../assets/youtube.png")}
              alt="Play Video"
              style={{ width: "60px" }}
            />
          </div>
        </div>
      ) : (
        <div
          style={{
            marginTop: 50,
            position: "relative",
            paddingBottom: 394,
            height: 0,
            overflow: "hidden",
            width: 700,
            border: "none",
            background: "#000",
            borderRadius: "5px",
          }}
        >
          <iframe
            title="Loom Video"
            src="https://wix.trackship.co/Wix-demo.mp4"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
            }}
          ></iframe>
        </div>
      )}
    </div>
  );
}
